.table {
    padding: 0 48px;
    width: inherit;
    border: 1px solid darkgrey;
}

.header {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
/* SivovaAlex-20240517-ActivitySettingsRollback*/
/*.header h3 {*/
/*    align-items: center;*/
/*    margin: 10px 0;*/
/*}*/

/*.settingInput {*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 10px;*/
/*    padding: 8px;*/
/*    margin-bottom: 10px;*/
/*}*/

.removeButton {
    cursor: pointer;
    width: 160px;
    border: none;
    background: none;
    color: #4183c4;
    text-decoration: underline;
    font-size: 18px;
}

.nameColumn {
    display: block;
    height: 66px;
    margin: -16px;
    padding-left: 16px;
    line-height: 3.3;
    text-decoration: underline;
}

.tableHeader {
    height: 40px;
}

.createButton {
    width: 220px;
    box-shadow: 3px 3px 2px lightgrey !important;
}

.container {
    overflow-y: auto;
    max-height: calc(100vh - 156px);
    margin-top: 16px;
}

.doctorInfoContent {
    padding: 0 48px;
    width: inherit;
    border: 1px solid darkgrey;
    text-align: left;
}

.doctorInfoHeader {
    margin-top: 32px;
    margin-bottom: 32px;
}

.doctorInfoButtons {
    margin-top: 32px;
    text-align: right;
}

.doctorEditorButtons {
    padding-top: 23px;
    margin-left: 48px;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 260px;
}

.doctorCreatorContent {
    margin-left: 48px;
    width: inherit;
    text-align: left;

}

.button {
    width: 120px;
    box-shadow: 3px 3px 2px lightgrey !important;
}

.linkCell {
    display: block;
    height: 66px;
    margin: -16px;
    padding-left: 16px;
    color: black;
    line-height: 3.3;
}

.linkCell:hover {
    color: black;
}

.list {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.field {
    margin-bottom: 16px;
}

.fieldLabel {
    font-weight: bold;
}

.fieldValue {
}

